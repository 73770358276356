#root {
  height: calc(100vh - 71px);
  margin: 0 auto;
}

.routesContainer {
  height: 100%;
}

@media (min-width: 600px) {
  .routesContainer {
    max-width: 84%;
    margin-left: 8%;
    padding-bottom: 16px;
  }
  .webView {
    display: none
  }
}