@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
html {
  font-size: 16px;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  font-family: "Montserrat", "Roboto", sans-serif !important;
  font-weight: 500 
}

/* #root {
  display: flex;
  height: 100vh;
} */
#root {
  height: calc(100vh - 71px);
  margin: 0 auto;
}

.routesContainer {
  height: 100%;
}

@media (min-width: 600px) {
  .routesContainer {
    max-width: 84%;
    margin-left: 8%;
    padding-bottom: 16px;
  }
  .webView {
    display: none
  }
}
